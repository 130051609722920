import "./experience.css";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper/modules";

function Experience() {
  const name = [
    {
      name: "Techvify",
      startDate: "10/10/2024",
      endDate: "10/10/2024",
      img: "https://techvify-software.com/wp-content/uploads/2022/11/techvify-software.jpg",
    },
    {
      name: "Capidemy",
      startDate: "10/10/2024",
      endDate: "10/10/2024",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_dF_K8IalG90CklyIvqeQQnIqaRmDHmTVevEizjORQHYmOXBWvtBADMCMIbdLhT-yfUU&usqp=CAU",
    },
    {
      name: "Moonlab",
      startDate: "10/10/2024",
      endDate: "10/10/2024",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS0Yj58TxF8ieMPtWGpHvx3--1cnrs4bwJaw&s",
    },
  ];

  const { ref, inView, entry } = useInView({
    /* Cấu hình tùy chọn */
    threshold: 0.6, // 50% phần tử vào vùng hiển thị thì coi như đã inView
    triggerOnce: false, // Chỉ kích hoạt một lần khi phần tử vào view
  });

  return (
    <div className="experience__container">
      <div className="experience__box">
        <div className="experience__title">
          My <span>Work Experience</span>
        </div>
        <div className="experience__main">
          {/* {name.map((info) => {
            return (
              <div
                className="job__box"
                ref={ref}
                style={{
                  opacity: inView ? "100%" : "0%",
                }}
              >
                <div className="job__img--box">
                  {" "}
                  <img src={info.img} alt="??" className="job__img" />
                </div>
                <div className="job__name">{info.name}</div>
                <div className="job__date">
                  {info.startDate} - {info.endDate}
                </div>
                <div className="job__btn--box">
                  <button className="job__btn">View more</button>
                </div>
              </div>
            );
          })} */}
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {name.map((company) => {
              return (
                <SwiperSlide>
                  <img src={company.img}></img>
                  {company.name}
                  <div>{company.startDate}</div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Experience;
